import { Buildings, Users } from "@phosphor-icons/react";
import { Skeleton, Tabs } from "antd";
import { useEffect, useMemo, useState } from "react";
import { Modal } from "x-wings";
import { getUserInfo, TUserInfo } from "./Api";
import { DEFAULT_KAM, OFFICES } from "./constant";

const ContactUsModal = ({ isModalOpen, handleCancel }: { isModalOpen: boolean; handleCancel: () => void }) => {
	const [userInfo, setUserInfo] = useState<TUserInfo | null>(null);
	const [isUserInfoLoading, setIsUserInfoLoading] = useState(false);

	useEffect(() => {
		if (isModalOpen) {
			setIsUserInfoLoading(true);
			getUserInfo()
				.then((res) => {
					setUserInfo(res.data);
				})
				.catch(() => {
					setUserInfo(null);
				})
				.finally(() => {
					setIsUserInfoLoading(false);
				});
		}
	}, [isModalOpen]);

	const contact = useMemo(() => {
		if (!userInfo?.kam) return DEFAULT_KAM;

		return {
			name: [userInfo.kam.first_name, userInfo.kam.last_name].filter(Boolean).join(" "),
			phone: userInfo.kam.phone,
			email: userInfo.kam.email
		};
	}, [userInfo]);

	return (
		<Modal
			footer={null}
			title="Contact us"
			open={isModalOpen}
			width={560}
			onCancel={handleCancel}
			destroyOnClose
			className="[&_.ant-drawer-body]:!pt-0">
			<Tabs
				defaultActiveKey="pointOfContacts"
				className="[&_.ant-tabs-tab]:!mt-1"
				items={[
					{
						label: (
							<span className="inline-flex items-center gap-1">
								<Users /> Point of contacts
							</span>
						),
						key: "pointOfContacts",
						children: (
							<div className="flex flex-col gap-3 mt-4 min-h-max">

								{!isUserInfoLoading && <div key={contact.phone}>
									<h3 className="font-semibold">
										Relationship Manager
									</h3>
									<p className="light-gray">{contact.name}</p>
									<p className="light-gray">
										<a
											className="hover:text-primary-600 hover:underline"
											href={`tel:${contact.phone.replace(/\D/g, "")}`}>
											{contact.phone}
										</a>
									</p>
									<p className="light-gray">
										<a
											className="hover:text-primary-600 hover:underline"
											href={`mailto:${contact.email}`}>
											{contact.email}
										</a>
									</p>
								</div>}
								{isUserInfoLoading && <Skeleton paragraph={{ rows: 3 }} />}

							</div>
						)
					},
					{
						label: (
							<span className="inline-flex items-center gap-1">
								<Buildings /> Offices
							</span>
						),
						key: "offices",
						children: (
							<div className="grid gap-3 mt-4 gap-x-4 md:grid-cols-2">
								{Object.entries(OFFICES).map(([city, address]) => (
									<div key={city}>
										<h3 className="font-semibold">{city} office</h3>
										<p className="light-gray max-w-[240px]">{address}</p>
									</div>
								))}
							</div>
						)
					}
				]}
			/>
		</Modal>
	);
};

export default ContactUsModal;
