import * as z from "zod";

export const WalletVirtualAccountReceiverElementSchema = z.object({
	BANK_TRANSFER: z.object({
		id: z.string(),
		entity: z.string().nullable().optional(),
		ifsc: z.string(),
		bank_name: z.string().nullable().optional(),
		name: z.string(),
		notes: z.array(z.any()),
		account_number: z.string(),
		account_type: z.string()
	}),
	UPI_TRANSFER: z.object({
		id: z.string(),
		upi_handle: z.string(),
		qr_code: z.string()
	})
});

export const WalletSchema = z.object({
	id: z.string(),
	org_id: z.string(),
	balance: z.number(),
	status: z.enum(["ACTIVE", "INACTIVE"]),
	zoho_customer_id: z.string(),
	created_at: z.string(),
	updated_at: z.string(),
	razorpay_customer_id: z.string()
});
export enum WALLET_TRANSACTION_TYPE {
	DEPOSIT = "DEPOSIT",
	PAY_FOR_ORDER = "PAY_FOR_ORDER",
	REFUND = "REFUND"
}

export enum WALLET_TRANSACTION_STATUS {
	SUCCESS = "SUCCESS",
	FAILED = "FAILED",
	PENDING = "PENDING"
}

export const WalletTransactionTypeSchema = z.nativeEnum(WALLET_TRANSACTION_TYPE);

export const WalletTransactionStatusSchema = z.nativeEnum(WALLET_TRANSACTION_STATUS);

export const TransactionMetadataSchema = z.object({
	orderId: z.string().optional(),
	smvOrderId: z.string().optional()
});

export enum PAYMENT_MODES {
	upi = "upi",
	credit_card = "credit_card",
	bank_transfer = "bank_transfer",
	wallet = "wallet"
}

export const WalletTransactionSchema = z.object({
	id: z.string(),
	wallet_id: z.string(),
	country: z
		.object({
			_id: z.string(),
			name: z.string(),
			symbol: z.string(),
			flag_symbol: z.string()
		})
		.optional(),
	type: WalletTransactionTypeSchema,
	amount: z.number(),
	status: WalletTransactionStatusSchema,
	payment_mode: z.nativeEnum(PAYMENT_MODES),
	failure_reason: z.string(),
	created_by: z.string(),
	updated_at: z.date(),
	created_at: z.date(),
	metadata: TransactionMetadataSchema
});

export const InitiateCardPaymentSchema = z.object({
	amount: z.number(),
	key: z.string(),
	txnid: z.string(),
	productinfo: z.string(),
	firstname: z.string(),
	email: z.string(),
	phone: z.string(),
	furl: z.string(),
	surl: z.string(),
	hash: z.string()
});
