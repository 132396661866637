import { Plus } from "@phosphor-icons/react";
import { InputNumber, Space } from "antd";
import toast from "components/common/Toaster";
import { initiateCardPayment } from "context/WalletContext/Api";
import { PAYU_PAYMENT_URL } from "env";
import { useState } from "react";
import { Mixpanel, MIXPANEL_EVENTS } from "utils/mixpanel";
import { Button } from "x-wings";

export const WALLET_SUGGESTED_AMOUNTS = [5000, 10000, 20000, 50000];

const CardsItem: React.FC = () => {
	const [amount, setAmount] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	const onPay = async () => {
		try {
			Mixpanel.track(MIXPANEL_EVENTS.WALLET_INITIATE_PAYMENT_BY_CC);
			setIsLoading(true);
			const { data } = await initiateCardPayment(amount);

			const formData = new FormData();
			Object.entries(data.data).forEach(([key, value]) => {
				formData.append(key, typeof value !== "string" ? String(value) : value);
			});

			const form = document.createElement('form');
			form.method = "post";
			form.action = PAYU_PAYMENT_URL;

			const newData = {
				...data.data,
				enforce_paymethod: "creditcard",
				drop_category: "CC|AMEX,CC|DINR"
			}

			for (const key in newData) {
				if (newData.hasOwnProperty(key)) {

					const hiddenField = document.createElement('input');
					hiddenField.type = 'hidden';
					hiddenField.name = key;
					hiddenField.value = String(newData[key as keyof typeof newData]);

					form.appendChild(hiddenField);
				}
			}

			document.body.appendChild(form);
			form.submit();

		} catch (error) {
			console.log(error);
			setIsLoading(false);
			toast.error("Card payment failed. Try again later.");
		}
	};

	return (
		<div className="md:p-8">
			<div className="mx-auto max-w-[439px]">
				<p className="mb-6 text-sm font-medium text-gray-800 md:mb-8">
					Click on the button below to recharge with your credit/ Debit card
				</p>
				<div className="mb-4 flex flex-col items-stretch justify-between gap-2 md:flex-row md:items-center md:gap-16">
					<span className="text-sm font-medium text-gray-600">Enter amount</span>
					<Space.Compact size="middle" className="mb-4">
						<InputNumber
							size="large"
							prefix="₹"
							className="md:w-[285px] w-full"
							controls={false}
							min={0}
							value={amount}
							placeholder="10,000"
							onChange={(value) => {
								if (value) setAmount(value);
								else setAmount(0);
							}}
							formatter={(value) => {
								if (typeof value !== "number") {
									value = parseFloat(value || "0");
								}
								return Intl.NumberFormat("en-IN").format(value);
							}}
						/>
					</Space.Compact>
				</div>
				<div className="mb-6 grid grid-cols-[repeat(auto-fill,minmax(100px,1fr))] gap-2">
					{WALLET_SUGGESTED_AMOUNTS.map((_) => (
						<Button
							block
							type="text"
							className="!h-7 !bg-primary-50 !px-2 !py-1 !text-sm !font-normal [&_span]:!px-1"
							onClick={() => setAmount((prev) => prev + _)}>
							<Plus size={10} weight="bold" />₹{Intl.NumberFormat("en-IN").format(_)}
						</Button>
					))}
				</div>
				<Button
					type="primary"
					size="large"
					htmlType="submit"
					loading={isLoading}
					block
					disabled={amount <= 0 || isLoading}
					onClick={onPay}>
					Pay using card
				</Button>
				<p className="mt-4 text-center text-xs text-gray-400">
					*Markup of 2% will be applicable on all card payments.
					<br />
					Debit Cards, Amex Cards and HDFC diner cards are not supported
				</p>
			</div>
		</div>
	);
};
export default CardsItem;

