export const CONTACTS = [
	{
		region: "Maharashtra",
		name: "Priya Solanki",
		phone: "9867381991",
		email: "priya.solanki@stampmyvisa.com"
	},
	{
		region: "Delhi",
		name: "Krishan Gopal",
		phone: "9870100296",
		email: "krishan.gopal@stampmyvisa.com"
	},
	{
		region: "Gujarat",
		name: "Dhwani Shah",
		phone: "9136867904",
		email: "dhwani.shah@stampmyvisa.com"
	},
	{
		region: "Kolkata",
		name: "Suvradeep Biswas",
		phone: "9355808003",
		email: "suvradeep.biswas@stampmyvisa.com"
	}
] as const;

export const DEFAULT_KAM = {
	phone: "9355808001",
	name: "Prakhar Agarwal",
	email: "prakhar.agarwal@stampmyvisa.com"
};

export const OFFICES = {
	Mumbai: "StampMyVisa, Asha House Block no 1, Gr Floor, Office Number 2, Near Bata Showroom, Dr Babasaheb Ambedkar Road, Dadar TT, Dadar East Mumbai-400014",
	Delhi: "803, 8th Floor, Mercantile House, KG Marg, Barakhamba, New Delhi - 110001",
	Ahmedabad:
		"StampMyVisa, Office No 733, 7th Floor,Sun Gravitas, Nr. Shyamal Cross Road, Rajmani Society, Satellite, Ahmedabad 380015",
	Kolkata: "StampMyVisa, GM 17, Rajdanga Main Road, Kolkata 700107"
} as const;
