import { apiInstance } from "api/instance";
import { z } from "zod";

/* 
{
  "_id": "660d5b047b81d55664eb14bc",
  "name": "Nuggets",
  "is_email_verified": true,
  "point_of_contact": {
    "mobile_number": "7652319087",
    "email": "shitiz+fass@stampmyvisa.com",
    "name": "Fassssss Furiousss"
  },
  "smv_wallet_id": "660d5bb97b81d55664eb1524",
  "status": "APPROVED",
  "created_at": "2024-04-03T13:35:00.244Z",
  "kam": {
    "_id": "64e4d0bf91be6000112cedee",
    "is_phone_verified": false,
    "is_email_verified": false,
    "visas_held": [],
    "type": "KAM",
    "last_login_at": "2023-08-22T14:57:11.432Z",
    "is_active": true,
    "is_deleted": false,
    "is_smv_user": true,
    "phone": "9015656309",
    "organisation_id": "64e4d0bf91be6000112ceded",
    "first_name": "Testing",
    "last_name": "Account",
    "created_at": "2023-08-22T15:14:07.270Z",
    "updated_at": "2025-01-21T11:28:23.360Z",
    "__v": 0,
    "roles": [
      "ADMIN",
      "CX",
      "DATA_ARCHITECT",
      "FINANCE",
      "KAM",
      "MANAGER",
      "VISA_EXPERT",
      "SECTOR_LEAD",
      "VISA_OPS",
      "LOGISTICS_HEAD"
    ],
    "email": "nitraj+KAM_Rohit@stampmyvisa.com",
    "frontend_app_version_key": "cedee_v32",
    "is_whatsapp_opt_in": true
  }
}*/

export const KamSchema = z.object({
    _id: z.string(),
    email: z.string(),
    phone: z.string(),
    first_name: z.string(),
    last_name: z.string(),
})

export const UserInfoSchema = z.object({
    _id: z.string(),
    name: z.string(),
    is_email_verified: z.boolean(),
    point_of_contact: z.object({
        mobile_number: z.string(),
        email: z.string(),
        name: z.string()
    }),
    kam: KamSchema.optional(),
})

export type TUserInfo = z.infer<typeof UserInfoSchema>;

export const getUserInfo = async () => {
    const response = await apiInstance.get<ApiResponse<TUserInfo>>("/users/user-info");
    return response.data;
};
